// Header.tsx
import React, { useState } from 'react';
import './Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';


function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="header">
      <div className="logo">
        <img src="/img/logoBidllama.png" alt="Bidllama Logo" />
      </div>
      <div className="menu_wrapper">
        <div className="menu-desktop">
          <ul>
            <li><a href='https://bid.bidllama.com/auctions' target='_blank' rel="noreferrer">Weekly Auctions</a></li>
            <li><a href='https://bidllama.com/how-it-works' target='_blank' rel="noreferrer">How It Works</a></li>
            <li><a href='https://bidllama.com/about' target='_blank' rel="noreferrer">About</a></li>
            <li><a href='https://bidllama.com/contact' target='_blank' rel="noreferrer">Contact</a></li>
          </ul>
        </div>
        <div className="menu-mobile" onClick={toggleMenu}>
          {menuOpen ? (
            // Display the close (X) icon
            <div className="close-icon"><FontAwesomeIcon icon={faCircleXmark} style={{  fontSize: '1.5em' }}  /></div>
          ) : (
            // Display the hamburger menu icon
            <div className="hamburger-menu">
              <div className="hamburger-bar"></div>
              <div className="hamburger-bar"></div>
              <div className="hamburger-bar"></div>
            </div>
          )}
        </div>
      </div>
      {menuOpen && (
        // Render the menu when menuOpen is true
        <div className="mobile-menu">
          <ul>
          <li><a href='https://bid.bidllama.com/auctions' target='_blank' rel="noreferrer">Weekly Auctions</a></li>
            <li><a href='https://bidllama.com/how-it-works' target='_blank' rel="noreferrer">How It Works</a></li>
            <li><a href='https://bidllama.com/about' target='_blank' rel="noreferrer">About</a></li>
            <li><a href='https://bidllama.com/contact' target='_blank' rel="noreferrer">Contact</a></li>
          </ul>
        </div>
      )}
    </header>
  );
}

export default Header;

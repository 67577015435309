import React, { useState } from "react";
import "./MainContent.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay';
import ApiResponseOverlay from "../ApiResponseOverlay/ApiResponseOverlay";



function MainContent() {
  const today = new Date();
  const currentDate = today.toISOString().split("T")[0];

  const [responseMessage, setResponseMessage] = useState<{ data: string; icon?: string; }>({ data: '', icon: 'defaultIcon' });
  // Define state variables for form inputs
  const [invoiceNumbers, setInvoiceNumbers] = useState([""]);
  const [lastNames, setLastNames] = useState([""]);
  const [pickupPerson, setPickupPerson] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [selectedTime, setSelectedTime] = useState<string>('');


  // State variables for error messages
  const [lastNameError, setLastNameError] = useState<string>("");
  const [pickupPersonError, setPickupPersonError] = useState<string>("");
  const [phoneNumberError, setPhoneNumberError] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [dateError, setDateError] = useState<string>("");
  const [timeError, setTimeError] = useState<string>("");
  const [invoiceNumbersError, setInvoiceNumbersError] = useState("");

  //If the process is succesfully clear the fields
  const clearFields = () => {
    setLastNames([""]);
    setPickupPerson("");
    setPhoneNumber("");
    setEmail("");
    setSelectedDate("");
    setSelectedTime("");
    setInvoiceNumbers([""]);
  };
  // Reusable validation function
  const validateField = (
    value: string | string[],
    setError: (error: string) => void,
    errorMessage: string
  ): boolean => {
    if (Array.isArray(value)) {
      // Check each element in the array
      for (const element of value) {
        if (!element.trim()) {
          setError(errorMessage);
          return false;
        }
      }
    } else {
      // For non-array values
      if (!value.trim()) {
        setError(errorMessage);
        return false;
      }
    }
    setError(''); // Clear the error message if the validation passes
    return true;
  };

  const isEmailValid = (
    email: string,
    setError: (error: string) => void,
    errorMessage: string
  ): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError(errorMessage);
      return false;
    }
    setError("");
    return true;
  };

  const isPhoneNumberValid = (
    phoneNumber: string,
    setError: (error: string) => void,
    errorMessage: string
  ): boolean => {
    // Regular expression for a simple phone number validation
    const phoneRegex = /^[0-9()\-\s]+$/;

    if (!phoneRegex.test(phoneNumber)) {
      setError(errorMessage);
      return false;
    }
    setError("");
    return true;
  };

  const isTextValid = (
    text: string,
    setError: (error: string) => void,
    errorMessage: string
  ): boolean => {
    // Regular expression for validating only textual characters
    const textRegex = /^[A-Za-z\s]+$/;

    if (!textRegex.test(text)) {
      setError(errorMessage);
      return false;
    }

    setError("");
    return true;
  };



  const validateForm = (): boolean => {
    let isValid = true;

    // Clear previous errors
    setLastNameError("");
    setPickupPersonError("");
    setPhoneNumberError("");
    setEmailError("");
    setDateError("");
    setTimeError("");
    setInvoiceNumbersError("");

    isValid = validateField(lastNames, setLastNameError, "Last Name is required") && isValid;
    isValid = validateField(selectedDate, setDateError, "Date is required") && isValid;
    isValid = validateField(selectedTime, setTimeError, "Time is required") && isValid;
    if (email !== "") {
      isValid = isEmailValid(email, setEmailError, "Invalid Email format") && isValid;
    }

    if (phoneNumber !== "") {
      isValid = isPhoneNumberValid(phoneNumber, setPhoneNumberError, "Invalid Phone format") && isValid;
      console.log(isValid)
    }

    if (pickupPerson !== "") {
      isValid = isTextValid(pickupPerson, setPickupPersonError, "Only text is allowed") && isValid;
      console.log(isValid)
    }

    //validate invoices fields
    validateField(
      invoiceNumbers,
      setInvoiceNumbersError,
      'Invoice  is required'
    );
    //if isValid = true, ok the form will be sent 
    return isValid;
  };


  const concatenateDateTime = (): string | null => {
    // Check if the selectedDate and selectedTime are in the correct format
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    const timeRegex = /^\d{2}:\d{2}$/;
    
    if (!dateRegex.test(selectedDate) || !timeRegex.test(selectedTime)) {
      return null;
    }
    console.log('Date', `${selectedDate}T${selectedTime}:00`)
    return `${selectedDate}T${selectedTime}:00`;
  };



  const formDataJson = {
    invoice_numbers: invoiceNumbers, // array to send multiple invoice numbers
    last_names: lastNames,
    pickup_person: pickupPerson,
    phone_number: phoneNumber,
    email: email,
    schedule_time: concatenateDateTime()

  };


  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(e.target.value);
  };

  const handleTimeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedTime(e.target.value);
  };


  const [showApiResponse, setShowApiResponse] = useState(false);

  const handleCloseApiResponse = () => {
    setShowApiResponse(false);
  };


  // Function to handle the form submission
  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }
    
    const formData = new FormData();
    setIsLoading(true);
    // Append each invoice number to the form data
    invoiceNumbers.forEach((number, index) => {
      formData.append(`invoice_number[${index}]`, number);
    });
    lastNames.forEach((lastName, index) => {
      formData.append(`last_name[${index}]`, lastName);
    });

    formData.append("pickup_person", pickupPerson);
    formData.append("phone_number", phoneNumber);

    // Concatenate date and time
    const dateTime = concatenateDateTime();

    if (dateTime !== null) {
      formData.append("scheduled_datetime", dateTime);
    }
    try {
      const response = await fetch(
        "https://api.retool.com/v1/workflows/67626129-e310-4ddf-a704-b2d08188f7c1/startTrigger?workflowApiKey=retool_wk_d4a53bb297f44b8ea39627c0a0135b16",
        {
          method: "POST",
          body: JSON.stringify(formDataJson),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        console.log(responseData.data);
        setResponseMessage(
          {
            data: responseData.data,
            icon: responseData.icon || 'defaultIcon',
          });
        if (responseData.data) {
          setShowApiResponse(true);
        }
        if (responseData.icon === "success") {
          clearFields();
        }

      }
    } catch (error) { } finally { setIsLoading(false); }
  };

 /*  const addField = (fieldArray: string[], setFieldArray: React.Dispatch<React.SetStateAction<string[]>>, defaultValue: string = "") => {
    if (fieldArray.length < 5) {
      setFieldArray([...fieldArray, defaultValue]);
    }
  }; */

  // Function to add an empty invoice number to the array
  const addInvoiceNumber = () => {
    if (invoiceNumbers.length < 5) {
      setInvoiceNumbers([...invoiceNumbers, ""]);
    }

    console.log('incoming', lastNames)
  };
  console.log(formDataJson)
  /* const addInvoiceAndLastNameFields = () => {
    if (invoiceNumbers.length < 5) {
      setInvoiceNumbers([...invoiceNumbers, ""]);
      setLastName(""); // Clear the last name field when adding a new invoice number
    }
  }; */
  const updateInvoiceAndLastName = (index: number, invoiceNumberValue: string, lastNameValue: string) => {
    const updatedInvoiceNumbers = [...invoiceNumbers];
    const updatedLastNames = [...lastNames];

    updatedInvoiceNumbers[index] = invoiceNumberValue;
    updatedLastNames[index] = lastNameValue;

    setInvoiceNumbers(updatedInvoiceNumbers);
    setLastNames(updatedLastNames);
  };


  // Function to update an invoice number in the array
  const updateInvoiceNumber = (index: number, value: string) => {
    const updatedInvoiceNumbers = [...invoiceNumbers];
    updatedInvoiceNumbers[index] = value;
    setInvoiceNumbers(updatedInvoiceNumbers);
  };

  const updateLastName = (index: number, value: string) => {
    const updatedLastNames = [...lastNames];
    updatedLastNames[index] = value;
    setLastNames(updatedLastNames);
  };

  // Function to remove an invoice number from the array
  const removeInvoiceNumber = (index: number) => {
    const updatedInvoiceNumbers = [...invoiceNumbers];
    const updatedLastNames = [...lastNames]; // Assuming you also have a state for lastNames
    updatedInvoiceNumbers.splice(index, 1);
    updatedLastNames.splice(index, 1);
    setInvoiceNumbers(updatedInvoiceNumbers);
    setLastNames(updatedLastNames); // Make sure to set the state for lastNames
  };
  

  return (
    <div className="inner_container">
      {isLoading && <LoadingOverlay message={responseMessage.data} isLoading={false} onDismiss={function (): void {
        throw new Error("Function not implemented.");
      }} />}

      {showApiResponse && (
        <ApiResponseOverlay
          responseData={{
            data: responseMessage.data,
            icon: responseMessage.icon ?? 'defaultIcon',
          }}
          onClose={handleCloseApiResponse}
        />
      )}
      <div className="info_container">
        <div className="arrow_back"></div>
        <div className="title">
          <h2>Schedule Pick Up</h2>
        </div>
        <div className="paragraph">
          Please enter your invoice number and the last name under the Customer
          Information section of your invoice. Select an arrival time window
          from the available options below. While we do our best to get our
          scheduled customers out as quickly as possible, it does not guarantee
          that we’ll get you loaded up within the time window. The time selected
          is an arrival time so that our team can plan ahead. Please note that
          scheduling for auctions is not available until the morning following
          the conclusion of the auction. If you are here to pick up more than
          one invoice please include them all.
        </div>
        <div className="image_holder">
          <img src="./img/Frame 4558.png" alt="" />
        </div>
      </div>
      <div className="form_container">
        <div className="form_header">Purchase information</div>
        <div className="form-body">


          {invoiceNumbers.map((invoiceNumber, index) => (
            <div className="form-body-content" style={{ margin: "1rem 0rem" }} key={index}>
              <input
                style={{ margin: "0 0.5rem 0 0" }}
                type="text"
                name="invoice_number"
                id=""
                placeholder={`Add Invoice Number`}
                value={invoiceNumber !== undefined ? invoiceNumber : ""}
                onChange={(e) => updateInvoiceAndLastName(index, e.target.value, lastNames[index])}
              />
              <input
                style={{ margin: "0 0.5rem 0 0" }}
                type="text"
                name="lastName"
                id=""
                placeholder={`Add Last Name`}
                value={lastNames[index]}
                onChange={(e) => updateInvoiceAndLastName(index, invoiceNumbers[index], e.target.value)}
              />



              {index > 0 && (
                <button
                  className="btn-remove"
                  onClick={() => removeInvoiceNumber(index)}
                >
                  <FontAwesomeIcon icon={faTrash} style={{ color: "#005470" }} />
                </button>
              )}
              <div>{invoiceNumbersError && <div className="error-message">{invoiceNumbersError}</div>}</div>
              <div>{lastNameError && <p className="error-message">{lastNameError}</p>}</div>
            </div>
          ))}

        </div>
        <button onClick={addInvoiceNumber}>
          <FontAwesomeIcon icon={faPlus} /> More Invoices
        </button>
        <div className="pickup_time_container">
          <div className="pickup_time">
            <input
              className="separator"
              type="date"
              min={currentDate}
              value={selectedDate}
              onChange={handleDateChange}
            />
            <p className="error-message">{dateError}</p>
            <select name="time" className="separator" value={selectedTime} onChange={handleTimeChange}>
              <option value="">Select Time</option>
              <option value="09:00">09:00 AM</option>
              <option value="09:15">09:15 AM</option>
              <option value="09:30">09:30 AM</option>
              <option value="09:45">09:45 AM</option>
              <option value="10:00">10:00 AM</option>
              <option value="10:15">10:15 AM</option>
              <option value="10:30">10:30 AM</option>
              <option value="10:45">10:45 AM</option>
              <option value="11:00">11:00 AM</option>
              <option value="11:15">10:15 AM</option>
              <option value="11:30">11:30 AM</option>
              <option value="11:45">11:45 AM</option>
              <option value="12:00">12:00 PM</option>
              <option value="12:15">12:15 PM</option>
              <option value="12:30">12:30 PM</option>
              <option value="12:45">12:45 PM</option>
              <option value="13:00">01:00 PM</option>
              <option value="13:15">01:15 PM</option>
              <option value="13:30">01:30 PM</option>
              <option value="13:45">01:45 PM</option>
              <option value="14:00">02:00 PM</option>
              <option value="14:15">02:15 PM</option>
              <option value="14:30">02:30 PM</option>
              <option value="14:45">02:45 PM</option>
              <option value="15:00">03:00 PM</option>
              <option value="15:15">03:15 PM</option>
              <option value="15:30">03:30 PM</option>
              <option value="15:45">03:45 PM</option>
              <option value="16:00">04:00 PM</option>
            </select>
            <p className="error-message">{timeError}</p>
          </div>
          <p>Authorized Pickup Person</p>
          <div className="picker_details">
            <input
              className="separator"
              type="text"
              name="pickup_person"
              id=""
              placeholder="Add a person's name"
              value={pickupPerson}
              onChange={(e) => setPickupPerson(e.target.value)}
            />
            <p className="error-message">{pickupPersonError}</p>
            <input
              className="separator"
              type="text"
              name="phone_number"
              id=""
              placeholder="Add a phone number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <p className="error-message">{phoneNumberError}</p>
            <input
              className="separator"
              type="email"
              name="email"
              id=""
              placeholder="Add email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <p className="error-message">{emailError}</p>
          </div>
          <p>
            I hereby authorize the above person by name and email to receive won
            items on my behalf.
          </p>
          <div className="btn-container">
            <button className="btn-medium" onClick={handleSubmit}>
              <FontAwesomeIcon icon={faCalendarDays} /> Schedule Your Pick Up
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainContent;

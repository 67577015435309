import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faTimes, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import  './ApiResponseOverlay.css';

interface ApiResponseOverlayProps {
  responseData: { data: string; icon: string };
  onClose: () => void;
}


const ApiResponseOverlay: React.FC<ApiResponseOverlayProps> = ({ responseData, onClose }) => {
    //const response = 
    console.log("icon ", responseData.icon)
  return (
    <div className="overlay">
      <div className="overlay-content">
        <button className="close-button" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        {responseData.icon === "success"?<FontAwesomeIcon icon={faCircleCheck} size="2xl" />:<FontAwesomeIcon icon={faTriangleExclamation} size="2xl"/>}
        
        <p>{responseData.data}</p>
        
      </div>
    </div>
  );
};

export default ApiResponseOverlay;

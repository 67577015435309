// Footer.tsx
import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer>
      <div className="footer_one">
        <div className="f_one_sec_one">
        <img src="/img/logoBidllama.png" alt="Bidllama Logo" />
          <div className="social">
            <a href='https://www.facebook.com/bidllama/' target='_blank' rel="noreferrer"><img src="/img/facebook.png" alt="Facebook Icon" /></a>
            <a href='https://www.instagram.com/bidllamaauction/' target='_blank' rel="noreferrer"><img src="/img/instagram.png" alt="Instagram Icon" /></a>
            
          </div>
        </div>
        <div className="f_one_sec_two">
          <ul>
            <li>
              <h3>AUCTION</h3>
            </li>
            <li><a href='https://bid.bidllama.com/auctions' target='_blank' rel="noreferrer">Weekly Auctions</a></li>
            <li><a href='https://bidllama.com/how-it-works' target='_blank' rel="noreferrer">How It Works</a></li>
          </ul>
          <ul>
            <li>
              <h3>SUBSCRIBE TO OUR NEWSLETTER</h3>
            </li>
            <li>Stay up to date on our weekly Auctions!</li>
          </ul>
        </div>
      </div>
      <div className="footer_two">
        <div className="f_two_sec_one">
          <div>
            <ul>
              <li>
                <h3>COMPANY</h3>
              </li>
              <li><a href='https://bidllama.com/about' target='_blank' rel="noreferrer">About</a></li>
              <li><a href='https://bidllama.com/contact' target='_blank' rel="noreferrer">Contact</a></li>
            </ul>
            <ul>
              <li>
                <h3>FINE PRINT</h3>
              </li>
              <li><a href='https://bid.bidllama.com/privacy' target='_blank' rel="noreferrer">Privacy Policy</a></li>
              <li><a href='https://bid.bidllama.com/terms' target='_blank' rel="noreferrer">Terms & Conditions</a></li>
            </ul>
          </div>
          <div className="newsletter">
            <li>
              <input type="text" />
            </li>
            <li>
              <button>Subscribe</button>
            </li>
          </div>
        </div>
        <div className="f_two_sec_two"></div>
      </div>
    </footer>
  );
}

export default Footer;

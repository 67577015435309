// LoadingOverlay.tsx
import React from 'react';
import PropTypes from 'prop-types';
import './LoadingOverlay.css';

interface LoadingOverlayProps {
  message?: string;
  isLoading: boolean;
  onDismiss: () => void;
}

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({ message, isLoading, onDismiss }) => {
  return (
    <div className={`loading-overlay ${isLoading ? 'visible' : 'hidden'}`}>
      
      {isLoading && <button onClick={onDismiss}>Dismiss</button>}
      <span className="loader"></span>
    </div>
  );
};

LoadingOverlay.propTypes = {
  message: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default LoadingOverlay;

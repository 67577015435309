// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Components/Header/Header';
import MainContent from './Components/MainContent/MainContent';
import Footer from './Components/Footer/Footer';
import AboutPage from './About/AboutPage';
import './App.css';
import IhaveArrivedPage from './IHaveArrived/IHaveArrivedPage';

function App() {
  return (
    <Router>
      <div className="container">
        <Header />
        <Routes>
          <Route path="/about" element={<AboutPage />} />
          <Route path="/ihavearrived/:paramValue" element={<IhaveArrivedPage />} />
          <Route path="/" element={<MainContent />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

// src/About/IhaveArrivedPage.tsx
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import "./IHaveArrivedPage.css";
import ApiResponseOverlay from "../Components/ApiResponseOverlay/ApiResponseOverlay";

const IhaveArrivedPage: React.FC = () => {
  const { paramValue } = useParams<{ paramValue: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [showApiResponse, setShowApiResponse] = useState(false);
  const [apiResponseData, setApiResponseData] = useState<{ data: string; icon: string }>({
    data: '',
    icon: '',
  });

  const handleCloseApiResponse = () => {
    setShowApiResponse(false);
  };

  const handleClick = async () => {
    try {
      setLoading(true);

      // Construct the API endpoint URL
      const apiUrl = `https://api.retool.com/v1/workflows/87ec2a20-2306-4490-931e-fba844e2d885/startTrigger?workflowApiKey=retool_wk_91830dfe5afe48b6b40fa5903c3f2d61`;

      // Construct the payload
      const payload = {
        queueId: paramValue,
        // Add other parameters as needed
      };

      // Send POST request to the API
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add other headers as needed
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        console.log('POST request successful');
        const responseData = await response.json();
        console.log(responseData.data);

        // Set the data for ApiResponseOverlay
        setApiResponseData({ data: responseData.data, icon: 'success' });
        setShowApiResponse(true);
      } else {
        console.error('POST request failed');
        // Set the data for ApiResponseOverlay
        setApiResponseData({ data: 'Failed to announce arrival', icon: 'error' });
        setShowApiResponse(true);
      }
    } catch (error) {
      console.error('Error:', error);
      // Set the data for ApiResponseOverlay
      setApiResponseData({ data: 'An error occurred', icon: 'error' });
      setShowApiResponse(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='splash'>
      <h1>Tell our team that you are ready to pick up your loot</h1>
      <p>It's time to claim your auction treasures at our warehouse. Head over now to pick up your curated victories and turn successful bids into tangible joys. Your loot awaits. Click the button below to notify our team that you're ready to collect your items and seize the moment!</p>
      <button onClick={handleClick}>Click here to announce that you have arrived</button>

      {/* Conditionally render loading indicator or response content */}
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div>
          {/* Add your page content here */}
          {/* Display the response content or any other UI elements */}
        </div>
      )}

      {/* Render ApiResponseOverlay conditionally */}
      {showApiResponse && (
        <ApiResponseOverlay responseData={apiResponseData} onClose={handleCloseApiResponse} />
      )}
    </div>
  );
};

export default IhaveArrivedPage;
